import { Profile } from './components';

export const states = [
  {
    name: 'base-layout.profile',
    url: '/profile',
    component: Profile,
    data: {
      requiresAuth: true,
    },
  },
];
