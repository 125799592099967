import { DeleteOutlined, EditOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import { UISref } from '@uirouter/react';
import { Button, Popconfirm } from 'antd';
import React from 'react';

import { VoidFnType } from '@/types/common';

export interface EditActionProps {
  uiSref: {
    to: string;
    params: Record<string, unknown>;
  };
  title?: string;
}

export interface DeleteActionProps {
  onConfirm: VoidFnType;
  title?: string;
}

const stopPropagationHandler = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const AddAction = ({ uiSref: { to, params }, title }: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Button type="primary" shape="round" icon={<PlusOutlined />}>{title}</Button>
      </a>
    </UISref>
  );
};

export const EditAction = ({ uiSref: { to, params }, title }: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Button type="primary" shape={title ? 'round' : 'circle'} icon={<EditOutlined />}>
          {title}
        </Button>
      </a>
    </UISref>
  );
};

export const DeleteAction = ({ onConfirm, title }: DeleteActionProps) => {
  return (
    <Popconfirm title={title || 'Sure to delete?'} onConfirm={onConfirm} onCancel={stopPropagationHandler}>
      <Button onClick={stopPropagationHandler} type="primary" danger={true} shape="circle" icon={<DeleteOutlined />} />
    </Popconfirm>
  );
};


export const ReportAction = ({ onClick }: any) => {
  return (
    <a onClick={stopPropagationHandler}>
      <Button type="primary" shape="circle" onClick={onClick} icon={<PaperClipOutlined />} />
    </a>
  );
};
