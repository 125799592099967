import { UISref } from '@uirouter/react';
import { Button, Result } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AccessDenied = () => {
  const subTitle = <>
    <FormattedMessage id={'common.access-denied.p1'} />
    <br />
    <FormattedMessage id={'common.access-denied.p2'} />
  </>;

  const BackHome = <UISref to={'base-layout.home'}>
    <a>
      <Button type="primary"><FormattedMessage id={'common.back-home'} /></Button>
    </a>
  </UISref>;

  return (
    <>
      <Result
        status="403"
        title={<FormattedMessage id={'common.access-denied.title'} />}
        subTitle={subTitle}
        extra={BackHome}
      />
    </>
  );
};
