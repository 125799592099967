import { IntlWrapper } from '@/components/intl-wrapper';
import {
  hashLocationPlugin,
  pushStateLocationPlugin,
  servicesPlugin,
  UIRouter,
  UIRouterReact,
  UIView,
} from '@uirouter/react';
import React from 'react';

import { StoresProvider } from '@/components/stores-provider';
import { states } from '@/states';
import * as stores from '@/stores';

import { routerConfig } from './router';

const App = () => {
  const config = (router: UIRouterReact) => routerConfig(router);
  const plugins = [hashLocationPlugin, pushStateLocationPlugin, servicesPlugin];

  return (
    <IntlWrapper>
      <StoresProvider stores={stores}>
        <UIRouter
          states={states}
          plugins={plugins}
          config={config}
        >
          <UIView />
        </UIRouter>
      </StoresProvider>
    </IntlWrapper>
  );
};

export default App;
