import React from 'react';

export function getResponseError(err: any) {
  const responseMessage = err.response?.data?.message;

  if (responseMessage) {
    if (Array.isArray(responseMessage)) {
      return responseMessage.map((e: string, i: number) => <div key={i}>{e}</div>);
    }

    return responseMessage;
  }

  return err.message;
}
