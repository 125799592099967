import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { UISref } from '@uirouter/react';
import { Avatar, Button, Col, Dropdown, Layout as AntdLayout, Menu, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '@/components/header/search';
import { PERMISSION_OBJ_ROLE, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { localeStore } from '@/stores';
import { VoidFnType } from '@/types/common';

import { RoleSwitcher } from '../role-switcher';

interface HeaderProps {
  collapsed: boolean;
  toggle: VoidFnType;
}

const menu = (
  <Menu>
    <Menu.Item>
      <UISref to="base-layout.profile">
        <a>
          <UserOutlined />
          <FormattedMessage id={'profile.header'} />
        </a>
      </UISref>
    </Menu.Item>

    <Menu.Item>
      <UISref to={'auth-logout'}>
        <a>
          <LogoutOutlined />
          <FormattedMessage id={'profile.logout'} />
        </a>
      </UISref>
    </Menu.Item>
  </Menu>
);

const PermissionedRoleSwitcher = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_READ]])(() => (
  <Col flex="0 1 300px">
    <RoleSwitcher />
  </Col>
));

export const Header = ({ collapsed, toggle, ...rest }: HeaderProps) => {
  const languages = localeStore.availableLanguages
    .map((language) => {
      const onClick = () => {
        localeStore.change(language);
      };

      return (
        <a key={language} onClick={onClick}>{
          language === localeStore.language
            ? <strong>{language}</strong>
            : language
        } </a>
      );
    });

  return (
    <AntdLayout.Header className="header">
      <Row align="middle" wrap={false}>
        <Col flex="1 1 200px">
          <Row align="middle">
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}

            <UISref to="base-layout.home">
              <a><h2>Backoffice</h2></a>
            </UISref>
          </Row>
        </Col>

        <Col flex="0 1 450px">
          <Search />
        </Col>

        {/* <Col flex="1 1 200px">
          <Button className="header-zone-btn">Zone to keep</Button>
        </Col> */}

        {/* <PermissionedRoleSwitcher /> */}

        {/* <Col flex="0 0 50px">
          <div className="lang">
            {languages}
          </div>
        </Col> */}

        <Col flex="0 0 56px">
          <Dropdown overlay={menu}>
            <Avatar size={'large'} icon={<UserOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </AntdLayout.Header>
  );
};
