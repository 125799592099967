export const fr = {
  'contact.header': 'Informations de contact',

  'contacts.header': 'Mes contacts',
  'contacts.create': 'Nouveau contact',
  'contacts.edit': 'Modifier le contact',

  'welcome.header': 'Page d\'accueil',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Mot de passe',

  'events.header': 'Mes événements',
  'events.create': 'Nouvel évènement',
  'events.edit': 'Modifier l\'événement',

  'home.header': 'Maison',
  'profile.header': 'Mon profil',
  'profile.logout': 'Se déconnecter',
  'settings.header': 'Mes paramètres',
  'forgot-login.header': 'Mot de passe oublié',
  'forgot-login.button.reset': 'Instructions de réinitialisation des e-mails',

  'registration.header': 'Inscription gratuite des membres',
  'registration.form.country': 'De campagne',
  'registration.form.language': 'Langue',
  'registration.button.continue': 'Continuez à configurer votre compte',
  'registration.button.sign-in': 'S\'identifier',
  'registration.text.acknowledge': 'En créant un compte, vous reconnaissez avoir lu notre politique de confidentialité et accepter nos conditions d\'utilisation.',
  'registration.text.registered': 'Vous avez déjà un compte?',

  'validation.messages.required': 'Obligatoire',
  'validation.messages.length': 'La longueur doit être comprise entre {0} et {1} symboles',
  'validation.messages.email': 'La valeur saisie n\'est pas un e-mail valide',

  'button.submit': 'Nous faire parvenir',
  'button.login': 'Connexion',
  'button.back': 'Retour',
  'button.cancel': 'Annuler',
  'button.settings': 'Réglages',
  'button.forgot-login': 'Mot de passe oublié',
  'button.registration': 'Inscription gratuite des membres',

  'menu.lots': 'Lots',
  'menu.bvs': 'BVs',
  'menu.users': 'Users',
  'menu.roles': 'Roles',
  'menu.campaigns': 'Campagnes',
  'menu.counterparties': 'Contreparties',
  'menu.cvs': 'CVs',
  'menu.products': 'Des produits',
  'menu.addresses': 'Adresses',
  'menu.counterparty-contracts': 'Contrats',
  'menu.proposal': 'Proposition',
  'menu.boardings': 'Embarquement',
  'menu.transit-orders': 'Ordres de transit',

  // Common pages
  'common.access-denied.title': 'Accès refusé',
  'common.access-denied.p1': 'Vous ne disposez pas des autorisations nécessaires pour accéder à cette page.',
  'common.access-denied.p2': 'Pour plus de détails, veuillez contacter le service client.',
  'common.back-home': 'Retour à la maison',
  'common.warning': 'Attention',

  // Table
  'table.actions': 'Actions',

  // Addresses
  'addresses.add-new': 'Ajoutez l\'adresse',
  'addresses.create-new': 'Créer une adresse',
  'addresses.address-number': 'Adresse #{0}',
  'addresses.name': 'Nom',
  'addresses.type': 'Taper',
  'addresses.country': 'De campagne',
  'addresses.city': 'Ville',
  'addresses.street': 'Rue',
  'addresses.street2': 'Rue 2',

  // BVs
  'bvs.add-new': 'Ajouter BV',
  'bvs.create-new': 'Créer BV',
  'bvs.bv-number': 'BV #{0}',
  'bvs.number': 'Nombre',
  'bvs.name': 'Nom',
  'bvs.status': 'Statut',
  'bvs.acceptance-date': 'Date D\'acceptation',
  'bvs.expiration-date': 'Date D\'expiration',

  // Campaigns
  'campaigns.add-new': 'Ajouter une campagne',
  'campaigns.create-new': 'Créer une campagne',
  'campaigns.campaign-number': 'Campagne #{0}',
  'campaigns.name': 'Nom',
  'campaigns.year': 'An',

  // Countcerparties
  'counterparties.add-new': 'Ajouter une contrepartie',
  'counterparties.create-new': 'Créer une contrepartie',
  'counterparties.counterparties-number': 'Contrepartie #{0}',
  'counterparties.name': 'Nom',
  'counterparties.type': 'Taper',

  // Countcerparty Contracts
  'counterparty-contracts.add-new': 'Ajouter un contrat',
  'counterparty-contracts.create-new': 'Créer un contrat',
  'counterparty-contracts.contract-number': 'Contracter #{0}',
  'counterparty-contracts.counterparty': 'Contrepartie',
  'counterparty-contracts.client-contract': 'Contrat client',
  'counterparty-contracts.shipowner-contract': 'Contrat d\'armateur',
  'counterparty-contracts.volume': 'Le Volume',
  'counterparty-contracts.price': 'Prix',
  'counterparty-contracts.statistics': 'Statistiques',
  'counterparty-contracts.accepted': 'Accepté',
  'counterparty-contracts.pending': 'En attente',
  'counterparty-contracts.received': 'A reçu',
  'counterparty-contracts.sampling': 'Échantillonnage',
  'counterparty-contracts.proposed': 'À proposer',
  'counterparty-contracts.total': 'Le total',

  // Contract Product
  'contract-product.product': 'Produit',
  'contract-product.quality': 'Qualité',
  'contract-product.gr': 'gr',
  'contract-product.volume': 'Le volume',

  // CVs
  'cvs.add-new': 'Ajouter CV',
  'cvs.create-new': 'Créer un CV',
  'cvs.cv-number': 'CV #{0}',
  'cvs.name': 'Nom',
  'cvs.number': 'Nombre',
  'cvs.volume': 'Le Volume',
  'cvs.price': 'Prix',
  'cvs.taxes': 'Les impôts',
  'cvs.counterparty': 'Contrepartie',
  'cvs.campaign': 'Campagne',

  // Lots
  'lots.add-new': 'Ajouter un lot',
  'lots.mass-add-new': 'Ajouter des lots',
  'lots.create-new': 'Créer un lot',
  'lots.lot-number': 'Lot #{0}',
  'lots.count': 'Compter',
  'lots.unblock-cv': 'Débloquer Cv',
  'lots.execution-cv': 'Exécution Cv',
  'lots.bv': 'BV',
  'lots.campaign': 'Campagne',
  'lots.proposal-contract': 'Contrat de proposition',
  'lots.proposal-status': 'Statut de la proposition',
  'lots.owner': 'Propriétaire',
  'lots.product': 'Produit',
  'lots.production-status': 'Statut de production',
  'lots.fo': 'FO',
  'lots.to': 'To',
  'lots.containers': 'Conteneurs',
  'lots.number': 'Numéro de lot',
  'lots.sacks-quantity': 'Quantité de sacs',
  'lots.weight': 'Poids',
  'lots.mass-create-warn': 'Les lots #{0} existent déjà',

  // Lot Analysis
  'lot.analysis.title': 'Analysis for Lot #{0}',
  'lot.analysis.address': 'Adresse',
  'lot.analysis.counterparty': 'Contrepartie',
  'lot.analysis.analysis-date': 'Date d\'analyse',
  'lot.analysis.sampling-date': 'Date d\'échantillonnage',
  'lot.analysis.sampling-method': 'Méthode d\'échantillonnage',
  'lot.analysis.analysis-reference': 'Référence d\'analyse',
  'lot.analysis.sampling-reference': 'Référence d\'échantillonnage',
  'lot.analysis.classification': 'Classification',
  'lot.analysis.document-url': 'URL du document',
  'lot.analysis.total-sample-weight': 'Poids total de l\'échantillon',
  'lot.analysis.specific-weight-100g': 'Poids spécifique sur 100g',
  'lot.analysis.graining': 'Grainage',
  'lot.analysis.humidity': 'Humidité',
  'lot.analysis.screening-scrap': 'Dépistage de la ferraille',
  'lot.analysis.flat-beans': 'Haricots plats',
  'lot.analysis.crabots': 'Crabots',
  'lot.analysis.foreign-bodies': 'Corps étranger',
  'lot.analysis.broken-beans': 'Haricots cassés',
  'lot.analysis.fragments': 'Fragments',
  'lot.analysis.shell': 'Coquille',
  'lot.analysis.materials-derived': 'Matériaux dérivés',
  'lot.analysis.moldy': 'Moisi',
  'lot.analysis.moths': 'Papillons de nuit',
  'lot.analysis.slate': 'Ardoise',
  'lot.analysis.germinated': 'Germé',
  'lot.analysis.violets': 'Violettes',
  'lot.analysis.buttered': 'Beurré',

  // Lot Fumigation
  'lot.fumigation.title': 'Fumigation for Lot #{0}',
  'lot.fumigation.location': 'Lieu',
  'lot.fumigation.start-date': 'Date de début',
  'lot.fumigation.end-date': 'Date de fin',

  // Lot Pledge
  'lot.pledge.title': 'Pledge for Lot #{0}',
  'lot.pledge.counterparty': 'Contrepartie',
  'lot.pledge.request-number': 'Numéro de demande',
  'lot.pledge.ltd-number': 'ltdNumber',
  'lot.pledge.das-dae': 'dasDae',

  // Lot Weighing
  'lot.weighing.title': 'Weighing for Lot #{0}',
  'lot.weighing.address': 'Adresse',
  'lot.weighing.counterparty': 'Contrepartie',
  'lot.weighing.date': 'Date',
  'lot.weighing.weight': 'Poids',

  // Products
  'products.add-new': 'Ajouter un produit',
  'products.create-new': 'Créer un produit',
  'products.product-number': 'Produit #{0}',
  'products.name': 'Nom',
  'products.type': 'Taper',
  'products.sampling-method': 'Méthode d\'échantillonnage',

  // Attachments
  'attachments.title': 'Pièces jointes pour #{0}',
  'attachments.uploading': 'Téléchargement',
  'attachments.start-upload': 'Commence le téléchargement',
  'attachments.name': 'Nom',
  'attachments.created': 'Créé',
  'attachments.Size': 'Taille',

  // Proposal
  'proposal.create': 'Créer une proposition',
  'proposal.new-status': 'Nouveau statut de proposition',
  'proposal.update-status': 'Mettre à jour le statut de la proposition pour le lot #{0}',
  'proposal.mass-update-status': 'État de la proposition de mise à jour en masse',
  'proposal.contract-initial': 'Client contractuel initial',
  'proposal.contract-final': 'Contrat Client Final',
  'proposal.mass-sampling': 'Échantillonnage de masse',
  'proposal.mass-accepted': 'Masse acceptée',
  'proposal.mass-rejected': 'Masse rejetée',
  'proposal.customer': 'Client',

  // Boardings
  'boardings.add-new': 'Ajouter un embarquement',
  'boardings.create-new': 'Créer un embarquement',
  'boardings.boarding-number': 'Embarquement #{0}',
  'boardings.contract': 'Contracter',
  'boardings.product': 'Produit',
  'boardings.harbor-address': 'Adresse du port',
  'boardings.destination-address': 'Adresse de destination',
  'boardings.campaign': 'Campagne',
  'boardings.exporter': 'Exportateur',
  'boardings.inspector': 'Inspecteur',
  'boardings.shipowner': 'Armateur',
  'boardings.courier': 'Courrier',
  'boardings.container-type': 'Type de conteneur',
  'boardings.number': 'Numéro',
  'boardings.volume': 'Le volume',
  'boardings.date': 'Date',

  // Transit Orders
  'transit-orders.add-new': 'Ajouter une commande de transit',
  'transit-orders.create-new': 'Créer une commande de transit',
  'transit-orders.transit-order-number': 'Ordre de transit #{0}',
  'transit-orders.boarding': 'Embarquement',
  'transit-orders.order-number': 'Numéro de commande',
  'transit-orders.booking-number': 'Numéro de réservation',
  'transit-orders.bill-of-lading-number': 'Numéro de connaissement',
  'transit-orders.forwarder-file-number': 'Numéro de fichier du transitaire',
  'transit-orders.ship-name': 'Nom de bateau',
  'transit-orders.good-to-embark': 'Bon à embarquer',
  'transit-orders.net-weight': 'Net Lester',
  'transit-orders.eta-date': 'Eta Date',
  'transit-orders.swb-date': 'Swb Date',
  'transit-orders.available-bv-volume': 'Volume Bv disponible pour la formule',
  'transit-orders.formulas-launched': 'Formules lancées',
  'transit-orders.gross-weight-diff': 'Poids Net Poids Brut Diff',
  'transit-orders.theoretical-weight': 'Poids théorique',
};
