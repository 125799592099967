import { buildDropdownOptionsFromState } from '@/utils/common';

export const getFormFields = (roles, isCreate) => {
  return [
    {
      name: 'email',
      componentName: 'input-email',
      params: {
        i18nLabel: 'users.email',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'password',
      componentName: 'input-password',
      params: {
        i18nLabel: 'users.password',
      },
      rules: [
        { required: isCreate },
        { min: 8 },
        { max: 32 },
      ],
    },
    {
      name: 'repeatPassword',
      componentName: 'input-password',
      params: {
        i18nLabel: 'users.repeat-password',
      },
      rules: [
        { required: isCreate },
        { min: 8 },
        { max: 32 },
      ],
    },
    {
      name: 'roles',
      componentName: 'dropdown-multiple',
      params: {
        i18nLabel: 'users.roles',
        options: buildDropdownOptionsFromState(roles),
      },
      rules: [{ required: true }],
    },
  ];
};