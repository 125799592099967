import { Layout as AntdLayout, Menu as AntdMenu } from 'antd';
import React from 'react';

import { withPermissions } from '@/hocs/permission';
import {
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { UISref } from '@uirouter/react';
import { FormattedMessage } from 'react-intl';

import {
  PERMISSION_OBJ_ROLE,
  PERMISSION_OBJ_USER,
  PERMISSION_READ,
} from '@/constants/permissions';

const { Sider } = AntdLayout;

interface MenuProps {
  collapsed: boolean;
  defaultSelectedKey?: string;
}

interface BaseMenuItemProps {
  state: string;
  icon: any;
  messageId: string;
}

type UnknownProps = Record<string, unknown>;

const BaseMenuItem = ({ state, icon, messageId, ...rest }: BaseMenuItemProps) => {
  return (
    <AntdMenu.Item icon={icon} {...rest} >
      <UISref to={state}>
        <a>
          <FormattedMessage id={messageId} />
        </a>
      </UISref>
    </AntdMenu.Item>
  );
};

const UsersMenuItem = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_READ]])((props: UnknownProps) => (
  <BaseMenuItem state="base-layout.users" messageId="menu.users" icon={<UserOutlined />} {...props} />
));

const RolesMenuItem = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_READ]])((props: UnknownProps) => (
  <BaseMenuItem state="base-layout.roles" messageId="menu.roles" icon={<SolutionOutlined />} {...props} />
));


export const Menu = ({ collapsed, defaultSelectedKey = 'users' }: MenuProps) => <Sider collapsed={collapsed}>
  <AntdMenu theme="dark" mode="inline" defaultSelectedKeys={[defaultSelectedKey]}>
    <UsersMenuItem key="users" />
    <RolesMenuItem key="roles" />
  </AntdMenu>
</Sider>;
