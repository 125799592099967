import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useRouter } from '@uirouter/react';
import { notification, Col, Row, Select } from 'antd';
import React, { useState, Dispatch, SetStateAction } from 'react';

import { api } from '@/api';
import { WrappedItemsResult } from '@/api/types';
import { getTranslatedString } from '@/utils';
import Text from 'antd/lib/typography/Text';

const { Option } = Select;
const SEARCH_TIMEOUT_MS: number = 500;

let timeout: NodeJS.Timeout;
let currentValue: string;

const routes = {
  user: { routeName: 'base-layout.users.edit', paramName: 'userId' },
  role: { routeName: 'base-layout.roles.edit', paramName: 'roleId' },
};

const deferredRequest = (
  value: string,
  callback: Dispatch<SetStateAction<cyrian.api.search.ResponseItem[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;
  setLoading(true);

  const request = () => {
    api.search.get({ q: value }).source
      .then(({ data }: WrappedItemsResult<cyrian.api.search.ResponseItem[]>) => {
        if (currentValue === value) {
          callback(data.items);
        }
      })
      .finally(() => setLoading(false));
  };

  timeout = setTimeout(request, SEARCH_TIMEOUT_MS);
};

export const Search = () => {
  const [data, setData] = useState<cyrian.api.search.ResponseItem[]>([]);
  const [value, setValue] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  const handleSearch = (searchText: string) => {
    searchText ? deferredRequest(searchText, setData, setLoading) : setData([]);
  };

  const handleChange = (selectedValue: string, option: any) => {
    setValue(null);
    const route = routes[option.data.type];

    router.stateService
      .go(route.routeName, { [route.paramName]: option.data.id })
      .catch((e) => {
        notification.warn({
          message: getTranslatedString('common.warning'),
          description: e.message,
        });
      });
  };

  const options = data
    .filter(({ field }: cyrian.api.search.ResponseItem) => field)
    .map(({ id, type, field }: cyrian.api.search.ResponseItem) => (
      <Option key={`${id}${type}`} data={{ id, type, field }} value={`${id}${type}`}>
        <Col>
          <Row>{field}</Row>
          <Row><Text type="secondary">{type}</Text></Row>
        </Col>
      </Option>
    ));

  const suffixIcon = loading ? <LoadingOutlined /> : <SearchOutlined />;

  return (
    <Select
      suffixIcon={suffixIcon}
      showSearch={true}
      value={value}
      placeholder={getTranslatedString('common.search')}
      style={{ width: 450 }}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};
