export const en = {
  'contact.header': 'Contact info',

  'contacts.header': 'My contacts',
  'contacts.create': 'New contact',
  'contacts.edit': 'Edit contact',

  'welcome.header': 'Welcome page',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Password',

  'events.header': 'My events',
  'events.create': 'New event',
  'events.edit': 'Edit event',

  'home.header': 'Home',
  'profile.header': 'My Profile',
  'profile.logout': 'Log Out',
  'settings.header': 'My settings',
  'forgot-login.header': 'Forgot Password',
  'forgot-login.button.reset': 'e-mail reset instructions',

  'registration.header': 'Free Member Registration',
  'registration.form.country': 'Country',
  'registration.form.language': 'Language',
  'registration.button.continue': 'Continue to Setup your Account',
  'registration.button.sign-in': 'Sign In',
  'registration.text.acknowledge': 'By creating an account, you acknowledge that you have read our Privacy Policy and agree to our Terms of Use.',
  'registration.text.registered': 'Already have an Account?',

  'validation.messages.required': 'Required',
  'validation.messages.length': 'Length should be {0} - {1} symbols',
  'validation.messages.email': 'Entered value is not a valid email',

  'button.submit': 'Submit',
  'button.login': 'Login',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.settings': 'Settings',
  'button.forgot-login': 'Forgot Password',
  'button.registration': 'Free Member Registration',

  'menu.users': 'Users',
  'menu.roles': 'Roles',
  'menu.proposal': 'Proposal',

  // Common pages
  'common.access-denied.title': 'Access Denied',
  'common.access-denied.p1': 'You don\'t have permissions to access this page.',
  'common.access-denied.p2': 'For more details, please contact customer support.',
  'common.back-home': 'Back Home',
  'common.warning': 'Warning',
  'common.search': 'Search...',

  // Table
  'table.actions': 'Actions',

  // Addresses
  'addresses.add-new': 'Add Address',
  'addresses.create-new': 'Create Address',
  'addresses.address-number': 'Address #{0}',
  'addresses.name': 'Name',
  'addresses.type': 'Type',
  'addresses.country': 'Country',
  'addresses.city': 'City',
  'addresses.street': 'Street',
  'addresses.street2': 'Street 2',

  // Users
  'users.add-new': 'Add User',
  'users.create-new': 'Create User',
  'users.user-number': 'User #{0}',
  'users.number': 'Number',
  'users.roles': 'Roles',
  'users.role': 'Role',
  'users.name': 'Name',
  'users.slugname': 'Slugname',
  'users.email': 'Email',
  'users.password': 'Password',
  'users.repeat-password': 'Repeat Password',
  'users.status': 'Status',
  'users.is-admin': 'Is Admin',
  'users.expiration-date': 'Expiration Date',
  'users.created-date': 'Created Date',

  // Roles
  'roles.add-new': 'Add Role',
  'roles.create-new': 'Create Role',
  'roles.role-number': 'Role #{0}',
  'roles.number': 'Number',
  'roles.permissions': 'Permissions',
  'roles.permissions-example': 'Permissions Example',
  'roles.password': 'Password',
  'roles.confirm-password': 'Confirm Password',
  'roles.role': 'Role',
  'roles.roles': 'Roles',
  'roles.name': 'Name',
  'roles.slugname': 'Slugname',
  'roles.user-name': 'User Name',
  'roles.status': 'Status',
  'roles.is-admin': 'Is Admin',
  'roles.expiration-date': 'Expiration Date',
  'roles.created-date': 'Created Date',

  // Campaigns
  'campaigns.add-new': 'Add Campaign',
  'campaigns.create-new': 'Create Campaign',
  'campaigns.campaign-number': 'Campaign #{0}',
  'campaigns.name': 'Name',
  'campaigns.year': 'Year',

  // Counterparties
  'counterparties.add-new': 'Add Counterparty',
  'counterparties.create-new': 'Create Counterparty',
  'counterparties.counterparties-number': 'Counterparty #{0}',
  'counterparties.name': 'Name',
  'counterparties.type': 'Type',

  // Countcerparty Contracts
  'counterparty-contracts.add-new': 'Add Contract',
  'counterparty-contracts.create-new': 'Create Contract',
  'counterparty-contracts.contract-number': 'Contract #{0}',
  'counterparty-contracts.counterparty': 'Counterparty',
  'counterparty-contracts.client-contract': 'Client Contract',
  'counterparty-contracts.shipowner-contract': 'Shipowner Contract',
  'counterparty-contracts.volume': 'Volume',
  'counterparty-contracts.price': 'Price',
  'counterparty-contracts.statistics': 'Statistics',
  'counterparty-contracts.accepted': 'Accepted',
  'counterparty-contracts.pending': 'Pending',
  'counterparty-contracts.received': 'Received',
  'counterparty-contracts.sampling': 'Sampling',
  'counterparty-contracts.proposed': 'To Be Proposed',
  'counterparty-contracts.total': 'Total',

  // Contract Product
  'contract-product.product': 'Product',
  'contract-product.quality': 'Quality',
  'contract-product.gr': 'gr',
  'contract-product.volume': 'Volume',

  // CVs
  'cvs.add-new': 'Add CV',
  'cvs.create-new': 'Create CV',
  'cvs.cv-number': 'CV #{0}',
  'cvs.name': 'Name',
  'cvs.number': 'Number',
  'cvs.volume': 'Volume',
  'cvs.price': 'Price',
  'cvs.taxes': 'Taxes',
  'cvs.counterparty': 'Counterparty',
  'cvs.campaign': 'Campaign',

  // Lots
  'lots.add-new': 'Add Lot',
  'lots.mass-add-new': 'Add Lots',
  'lots.create-new': 'Create Lot',
  'lots.lot-number': 'Lot #{0}',
  'lots.count': 'Count',
  'lots.unblock-cv': 'Unblock Cv',
  'lots.execution-cv': 'Execution Cv',
  'lots.bv': 'BV',
  'lots.campaign': 'Campaign',
  'lots.proposal-contract': 'Proposal Contract',
  'lots.proposal-status': 'Proposal Status',
  'lots.owner': 'Owner',
  'lots.product': 'Product',
  'lots.production-status': 'Production Status',
  'lots.fo': 'FO',
  'lots.to': 'To',
  'lots.containers': 'Containers',
  'lots.number': 'Lot Number',
  'lots.sacks-quantity': 'Sacks Quantity',
  'lots.weight': 'Weight',
  'lots.mass-create-warn': 'Lots #{0} already exist',

  // Lot Analysis
  'lot.analysis.title': 'Analysis for Lot #{0}',
  'lot.analysis.address': 'Address',
  'lot.analysis.counterparty': 'Counterparty',
  'lot.analysis.analysis-date': 'Analysis Date',
  'lot.analysis.sampling-date': 'Sampling Date',
  'lot.analysis.sampling-method': 'Sampling Method',
  'lot.analysis.analysis-reference': 'Analysis Reference',
  'lot.analysis.sampling-reference': 'Sampling Reference',
  'lot.analysis.classification': 'Classification',
  'lot.analysis.document-url': 'Document Url',
  'lot.analysis.total-sample-weight': 'TotalSample Weight',
  'lot.analysis.specific-weight-100g': 'Specific Weight On 100g',
  'lot.analysis.graining': 'Graining',
  'lot.analysis.humidity': 'Humidity',
  'lot.analysis.screening-scrap': 'Screening Scrap',
  'lot.analysis.flat-beans': 'Flat Beans',
  'lot.analysis.crabots': 'Crabots',
  'lot.analysis.foreign-bodies': 'Foreign Bodies',
  'lot.analysis.broken-beans': 'Broken Beans',
  'lot.analysis.fragments': 'Fragments',
  'lot.analysis.shell': 'Shell',
  'lot.analysis.materials-derived': 'Materials Derived',
  'lot.analysis.moldy': 'Moldy',
  'lot.analysis.moths': 'Moths',
  'lot.analysis.slate': 'Slate',
  'lot.analysis.germinated': 'Germinated',
  'lot.analysis.violets': 'Violets',
  'lot.analysis.buttered': 'Buttered',

  // Lot Fumigation
  'lot.fumigation.title': 'Fumigation for Lot #{0}',
  'lot.fumigation.location': 'Location',
  'lot.fumigation.start-date': 'Start Date',
  'lot.fumigation.end-date': 'End Date',

  // Lot Pledge
  'lot.pledge.title': 'Pledge for Lot #{0}',
  'lot.pledge.counterparty': 'Counterparty',
  'lot.pledge.request-number': 'Request Number',
  'lot.pledge.ltd-number': 'ltdNumber',
  'lot.pledge.das-dae': 'dasDae',

  // Lot Weighing
  'lot.weighing.title': 'Weighing for Lot #{0}',
  'lot.weighing.address': 'Address',
  'lot.weighing.counterparty': 'Counterparty',
  'lot.weighing.date': 'Date',
  'lot.weighing.weight': 'Weight',

  // Products
  'products.add-new': 'Add Product',
  'products.create-new': 'Create Product',
  'products.product-number': 'Product #{0}',
  'products.name': 'Name',
  'products.type': 'Type',
  'products.sampling-method': 'Sampling Method',

  // Attachments
  'attachments.title': 'Attachments for #{0}',
  'attachments.uploading': 'Uploading',
  'attachments.start-upload': 'Start Upload',
  'attachments.name': 'Name',
  'attachments.created': 'Created',
  'attachments.Size': 'Size',

  // Proposal
  'proposal.create': 'Create Proposal',
  'proposal.new-status': 'New Proposal Status',
  'proposal.update-status': 'Update Proposal Status for Lot #{0}',
  'proposal.mass-update-status': 'Mass Update Proposal Status',
  'proposal.contract-initial': 'Contract Client Initial',
  'proposal.contract-final': 'Contract Client Final',
  'proposal.mass-sampling': 'Mass Sampling',
  'proposal.mass-accepted': 'Mass Accepted',
  'proposal.mass-rejected': 'Mass Rejected',
  'proposal.customer': 'Customer',

  // Boardings
  'boardings.add-new': 'Add Boarding',
  'boardings.create-new': 'Create Boarding',
  'boardings.boarding-number': 'Boarding #{0}',
  'boardings.contract': 'Contract',
  'boardings.product': 'Product',
  'boardings.harbor-address': 'Harbor Address',
  'boardings.destination-address': 'Destination Address',
  'boardings.campaign': 'Campaign',
  'boardings.exporter': 'Exporter',
  'boardings.inspector': 'Inspector',
  'boardings.shipowner': 'Shipowner',
  'boardings.courier': 'Courier',
  'boardings.container-type': 'Container Type',
  'boardings.number': 'Number',
  'boardings.volume': 'Volume',
  'boardings.date': 'Date',

  // Transit Orders
  'transit-orders.add-new': 'Add Transit Order',
  'transit-orders.create-new': 'Create Transit Order',
  'transit-orders.transit-order-number': 'Transit Order #{0}',
  'transit-orders.boarding': 'Boarding',
  'transit-orders.order-number': 'Order Number',
  'transit-orders.booking-number': 'Booking Number',
  'transit-orders.bill-of-lading-number': 'Bill Of Lading Number',
  'transit-orders.forwarder-file-number': 'Forwarder File Number',
  'transit-orders.ship-name': 'Ship Name',
  'transit-orders.good-to-embark': 'Good To Embark',
  'transit-orders.net-weight': 'Net Weight',
  'transit-orders.eta-date': 'Eta Date',
  'transit-orders.swb-date': 'Swb Date',
  'transit-orders.available-bv-volume': 'Available Bv Volume For Formula',
  'transit-orders.formulas-launched': 'Formulas Launched',
  'transit-orders.gross-weight-diff': 'Net Weight Gross Weight Diff',
  'transit-orders.theoretical-weight': 'Theoretical Weight',
};
