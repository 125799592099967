import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { rolesStore } from '@/stores';
import { permissionsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';

export const RolesForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.roles.create';
  const { roleId } = transition.router.globals.params;
  const role = rolesStore.items.find(({ id }: any) => id === +roleId);
  const permissions = permissionsStore.items.map((item) => item);

  const [currentPermissions, setCurrentPermissions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(!isCreate);

  useEffect(() => {
    if (!isCreate) {
      api.roles.getOne(roleId).source
        .then(({ data }) => {
          const perm = data.permissions.map(item => item.id);
          setCurrentPermissions(perm);
          setLoading(false);
        })
    }
  }, []);
  
  const onClose = () => {
    transition.router.stateService.go('base-layout.roles');
  };

  if (loading) {
    return (<>Loading</>);
  }

  const initValues = !isCreate && role
    ? {
      ...role,
      permissions: currentPermissions,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('roles.create-new')
    : getTranslatedString('roles.role-number', { 0: roleId });

  const formFields = getFormFields(permissions);

  const getNormalizedValuesInCreate = (values: any) => {
    return {
      ...values,
      permissions: undefined,
    };
  };

  const getNormalizedValuesInUpdate = (values: any) => {
    return {
      ...values,
    };
  };

  const resourceController = withoutRepeatValue(initValues, {
    create: async (values: any) => {
      const newRoleResponse = await api.roles.create(getNormalizedValuesInCreate(values)).source;

      const roleId = newRoleResponse.data.id;
      const newRoleWithNewPermissionsResponse = await api.roles.assignPermissions(roleId, values.permissions).source;
      rolesStore.addItem(newRoleWithNewPermissionsResponse.data);

      transition.router.stateService.go('base-layout.roles.edit', { roleId });

      return newRoleWithNewPermissionsResponse;
    },
    update: async (values: any) => {
      await api.roles.update(role.id, getNormalizedValuesInUpdate(values)).source;
    
      const responseWithNewPermissions = await api.roles.updatePermissions(role.id, values.permissions).source;
      rolesStore.updateItem(responseWithNewPermissions.data);

      return responseWithNewPermissions;
    }
  });

  return (
    <DrawerForm
      resourceId={roleId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      onFinish={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !role}
    />
  );
});
