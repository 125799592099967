import { authStore } from '@/stores';
import { AuthLoginForm } from './components/Login';
import { AuthRegisterForm } from './components/Signup';

export const states = [
  {
    name: 'auth-login',
    url: '/auth/login',
    component: AuthLoginForm,
  },
  {
    name: 'auth-signup',
    url: '/auth/signup',
    component: AuthRegisterForm,
  },
  {
    name: 'auth-logout',
    url: '/auth/logout',
    data: {
      requiresAuth: true,
    },
    resolve: [
      {
        token: '$val',
        deps: ['$transition$'],
        resolveFn (transition: any) {
          const goToState = transition?.router?.stateService?.go;
          authStore.logout();

          if (goToState) {
            goToState('auth-login');
          }
        },
      },
    ],
  },
];
