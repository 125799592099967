import axios from 'axios';
import qs from 'querystring';

import { API_URL, LOCAL_STORAGE_KEY__ACCESS_TOKEN } from '@/constants';

const defaultTimeout = 30000;
const defaultBaseURL = API_URL;

export const request = (settings: any) => {
  const {
    method = 'get',
    url,
    baseURL = null,
    data = {},
    headers = {},
    timeout = defaultTimeout,
    params = {},
    withCredentials = false,
    config = {},
  } = settings;

  let cancelToken: any;
  const token = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

  const instance = axios.create({
    baseURL: baseURL || defaultBaseURL,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    timeout,
    withCredentials,
    cancelToken: new axios.CancelToken(c => cancelToken = c),
    paramsSerializer: (params1: any): string => qs.stringify(params1),
    ...config,
  });

  return {
    cancel: cancelToken,
    source: instance.request({ url, method, params, data })
      // tslint:disable-next-line:no-shadowed-variable
      .then(({ data }: any) => data)
      .catch((e) => {
        if (e && e.response) {
          const response: any = e.response;

          if (response.data && response.data.error) {
            const error = response.data.error;

            // Session Expired
            if (error.code === 6) {
              window.location.href = '';
            }
          }
        }
        throw e;
      }),
  };
};
