import { LocalStorageTokenProvider } from '@/utils/access-token-provider';

import { api } from '@/api';
import { AuthStore } from './auth';
import { CachedStorage, CollectionStore, StoreDataLoader } from './collection';
import { LocaleStore } from './locale';

export const authStore = new AuthStore(new LocalStorageTokenProvider());
export const localeStore = new LocaleStore();
export const usersStore = new CollectionStore<cyrian.api.users.User,
  cyrian.api.users.GetParams>(new StoreDataLoader(api.users.getAll), new CachedStorage());  
export const rolesStore = new CollectionStore<cyrian.api.roles.Role,
  cyrian.api.roles.GetParams>(new StoreDataLoader(api.roles.getAll), new CachedStorage());  
export const permissionsStore = new CollectionStore<cyrian.api.permissions.Permission,
  cyrian.api.permissions.GetParams>(new StoreDataLoader(api.permissions.getAll), new CachedStorage());  

