import { useRouter } from '@uirouter/react';
import { Button, Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { authStore } from '@/stores';

export const RoleSwitcher = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>(authStore.profile.roles);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    async function loadRoles() {
      setLoading(true);

      const { source } = api.roles.getAll({ limit: 100 });
      const { data: { items = [] } } = await source;

      setRoles(items);
      setLoading(false);
    }

    if (!roles.length) {
      loadRoles();
    }
  }, [roles]);

  async function onChange(newSelectedRoles: number[]) {
    setSelectedRoles(newSelectedRoles);
  }

  async function onClick() {
    setLoading(true);
    try {
      await authStore.loginWithRoles(selectedRoles);
      await router.stateService.reload();
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Row>
      <Col span={15}>
        <Select
          mode="multiple"
          className="role-switcher"
          onChange={onChange}
          value={selectedRoles}
          loading={loading}
          maxTagCount="responsive"
        >
          {roles.map(role => <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>)}
        </Select>
      </Col>
      <Col span={8} offset={1}>
        <Button type="primary" onClick={onClick}>Change</Button>
      </Col>
    </Row>
  );
};
