import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.users.GetParams): ApiResponse<WrappedResult<cyrian.api.users.User[]>> {
    return request({
      method: 'get',
      url: '/users',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
  },
  get(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'get',
      url: `/users/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  assignRoles(id: number, roleIds: number[]) {
    return request({
      method: 'post',
      url: `/users/${id}/assign-roles`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {roleIds},
    })
  },
  updateRoles(id: number, roleIds: number[]) {
    return request({
      method: 'post',
      url: `/users/${id}/update-roles`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {roleIds},
    })
  },
  revokeRoles(id: number, roleIds: number[]) {
    return request({
      method: 'post',
      url: `/users/${id}/revoke-roles`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {roleIds},
    })
  },
  create(data: cyrian.api.users.UsersParams): ApiResponse<WrappedResult<cyrian.api.users.User>> {
    return request({
      method: 'post',
      url: '/users',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  update(id: number, data: cyrian.api.users.UsersParams): ApiResponse<WrappedResult<cyrian.api.users.User>> {
    return request({
      method: 'patch',
      url: `/users/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  delete(userId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/users/${userId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
