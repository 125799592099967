import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_ROLE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { RolesForm } from './components/form';
import { Roles } from './components/table';

export const states = [
  {
    name: 'base-layout.roles',
    url: '/roles',
    component: Roles,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ROLE, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.roles.create',
    url: '/new',
    component: RolesForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ROLE, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.roles.edit',
    url: '/:roleId/edit',
    component: RolesForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ROLE, PERMISSION_UPDATE],
      ],
    },
  },
];
