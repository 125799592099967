import jwtDecode from 'jwt-decode';
import { action, makeObservable, observable } from 'mobx';

import { login, loginAs, permissions, register, session } from '@/api/auth';
import { AUTH_URL } from '@/constants';
import { AccessTokenProvider } from '@/utils/access-token-provider';

export class AuthStore {
  @observable loggedIn: boolean = false;
  @observable profile: any = {};
  @observable permissions: Record<string, number> = {};

  constructor(private readonly tokenProvider: AccessTokenProvider) {
    makeObservable(this);
  }

  @action async completeLogin() {
    const token = this.tokenProvider.get();

    if (token) {
      const decoded = jwtDecode(token) as any;
      await this.loadPermissions(token);

      this.profile = decoded?.user;
      this.loggedIn = true;
    }
  }

  @action logout() {
    this.loggedIn = false;
    this.profile = {};
    this.permissions = {};
    this.tokenProvider.remove();
  }

  isAuthorized = () => {
    return this.loggedIn && !!this.tokenProvider.get();
  }

  loadPermissions = async (token: string) => {
    const perms = await permissions(token);
    this.setPermissions(perms);
  }

  @action setPermissions = (perms: Record<string, number>) => {
    this.permissions = {};
    this.permissions = perms;
  }

  hasPermissions = (perms: [string, number][]) => {
    return perms
      .reduce((final: boolean, [obj, mask]: any) => {
        return final && (this.permissions[obj] & mask) === mask;
      }, true);
  }

  hasAtLeastOnePermission = (perms: [string, number][]) => {
    return perms
      .reduce((final: boolean, [obj, mask]: any) => {
        return final || (this.permissions[obj] & mask) === mask;
      }, false);
  }

  checkSession = async () => {
    const token = this.tokenProvider.get();
    try {
      if (token) {
        await session(token);
        await this.completeLogin();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        this.tokenProvider.remove();
      }
    }
  }

  loginWithEmail = async (email: string, password: string) => {
    const response = await login({ email, password });

    const { token } = response.data;
    this.tokenProvider.set(token);

    await this.completeLogin();
  }

  register = async (email: string, password: string, repeatPassword: string) => {
    await register({ email, password, repeatPassword });
  }

  loginWithSaml = async (provider: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const win = window.open(
        `${AUTH_URL}/saml/${provider}/login`,
        'saml-login',
        'height=500,width=500,left=700,top=250',
      );

      if (win) {
        const onMessage = async (event: any) => {
          window.removeEventListener('message', onMessage);
          win.close();

          this.tokenProvider.set(event.data);
          await this.completeLogin();

          resolve();
        };
        window.addEventListener('message', onMessage);
      }
    });
  }

  loginWithRoles = async (roleIds: number[]) => {
    const accessToken = this.tokenProvider.get();
    const response = await loginAs({ roleIds }, accessToken);

    const { token } = response.data;
    this.tokenProvider.set(token);

    await this.completeLogin();
  }
}
