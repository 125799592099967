import { Transfer } from '@/components/form-fields/transfer';
import { Checkbox } from './checkbox';
import { DatePicker } from './datapicker';
import { Dropdown } from './dropdown';
import { DropdownMultiple } from './dropdown-multiple';
import { Input } from './input';
import { InputNumber } from './input-number';
import { InputEmail } from './input-email';
import { InputPassword } from './input-password';
import { InputPasswordRepeat } from './input-password-repeat';
import { LabeledSpan } from './labeled-span';

const fieldsFactory = (fieldType?: string): any => {
  switch (fieldType) {
    case 'checkbox':
      return Checkbox;
    case 'input':
      return Input;
    case 'input-email':
      return InputEmail;
    case 'input-password':
      return InputPassword;
    case 'input-password-repeat':
      return InputPasswordRepeat;
    case 'dropdown':
      return Dropdown;
    case 'dropdown-multiple':
      return DropdownMultiple;
    case 'input-number':
      return InputNumber;  
    case 'datepicker':
      return DatePicker;
    case 'labeled-span':
      return LabeledSpan;
    case 'transfer':
      return Transfer;
    default:
      throw new Error(`Unknown field type ${fieldType}`);
  }
};

export {
  fieldsFactory,
  Checkbox,
  Input,
  InputEmail,
  InputPassword,
  InputPasswordRepeat,
  Dropdown,
  DropdownMultiple,
  InputNumber,
  DatePicker,
  LabeledSpan,
  Transfer,
};
