import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_USER,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { UserForm } from './components/form';
import { Users } from './components/table';

export const states = [
  {
    name: 'base-layout.users',
    url: '/users',
    component: Users,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_USER, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.users.create',
    url: '/new',
    component: UserForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_USER, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.users.edit',
    url: '/:userId/edit',
    component: UserForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_USER, PERMISSION_UPDATE],
      ],
    },
  },
];
