import { useEffect, useState, DependencyList, EffectCallback } from 'react';

import { CollectionStore } from '@/stores/collection';

export const defaultMassUpdateCashedStores = (cachedStores: CollectionStore<any, any>[]) => {
  // TODO remove limit when will refactor store
  const promises = cachedStores.map((cachedStore => cachedStore.asyncItems({ limit: 500 })));
  return Promise.all(promises);
};

export const useMassUpdateCashedStores = (cachedStores: CollectionStore<any, any>[], deps: DependencyList = []) => {
  const [loading, setLoading] = useState<boolean>(false);

  const effect: EffectCallback = () => {
    setLoading(true);

    defaultMassUpdateCashedStores(cachedStores)
      .then(() => setLoading(false));
  };

  useEffect(effect, deps);

  return { loading, setLoading };
};
