import { PlusOutlined } from '@ant-design/icons';
import { UIView } from '@uirouter/react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_ROLE } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { permissionsStore, rolesStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

const roles = rolesStore;
const permissions = permissionsStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_CREATE]])(({ onClick }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={<PlusOutlined />}>
      <FormattedMessage id="roles.add-new" />
    </Button>
  );
});

export const Roles = observer(({ transition }: any) => {
  const items = roles.items;

  useMassUpdateCashedStores([roles, permissions]);

  const onAdd = () => {
    transition.router.stateService.go('base-layout.roles.create');
  };

  return (
    <>
      <CreateButton onClick={onAdd} />
      <Table rowKey="id" columns={columns} dataSource={[...items]} store={roles} />
      <UIView />
    </>
  );
});
