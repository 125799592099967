import { Home } from './components';

export const states = [
  {
    name: 'base-layout.home',
    url: '/home',
    component: Home,
    data: {
      requiresAuth: true,
    },
  },
];
