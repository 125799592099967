import { Form, FormItemProps, Input as AntdInputPassword, InputProps as AntdInputPasswordProps } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputPasswordProps extends FormItemProps, AntdInputPasswordProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
}

export const InputPasswordRepeat = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    type = 'password',
    ...props
  }: InputPasswordProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules}>
      <AntdInputPassword.Password disabled={disabled} type={type} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
    </Form.Item>
  );
};