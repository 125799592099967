import users from './users';
import roles from './roles';
import permissions from './permissions';
import search from './search';

export const api: { [key: string]: any } = {
  users,  
  roles,
  permissions,
  search,
};
