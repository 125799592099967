import {
  ColumnsType,
  ColumnType,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import { ITypedKeyValueObject } from '@/types/common';

export const toQueryFormat = (genericFilters, pagination: TablePaginationConfig, filters: ITypedKeyValueObject<any>,
                              sorter: SorterResult<any>, extra: TableCurrentDataSource<any>) => {
  const { pageSize, current }: TablePaginationConfig = pagination;
  const { field, order }: SorterResult<any> = sorter;
  const normalizedField = Array.isArray(field) ? field.join('.') : field;
  const sort = order ? { sort: `${normalizedField}:${order === 'ascend' ? 1 : -1}` } : {};

  return {
    limit: pageSize,
    skip: pageSize * ((current < 1) ? 0 : current - 1),
    ...sort,
    ...genericFilters(filters),
  };
};

export const getGenericFilters = (columns: ColumnsType) => {
  return (filtersValue: ITypedKeyValueObject<any>) => columns
    .filter(({ filters, filterDropdown, dataIndex }: ColumnType<any>) => (filters || filterDropdown)
      && filtersValue[String(dataIndex)])
    .reduce((prev: ITypedKeyValueObject<any>, { dataIndex }: ColumnType<any>) => ({
      ...prev,
      [String(dataIndex)]: filtersValue[String(dataIndex)][0],
    }), {});
};
