import { Form, FormItemProps, Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps, CheckboxOptionType as AntdCheckboxOptionType} from 'antd';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface CheckboxProps extends FormItemProps, AntdCheckboxProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
}

export const Checkbox = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    ...props
  }: CheckboxProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules}>
      <AntdCheckbox disabled={disabled} />
    </Form.Item>
  );
};
