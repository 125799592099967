import { DatePicker as AntdDatePicker, Form, FormItemProps } from 'antd';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface DatePickerProps extends FormItemProps, Ii18nLabel {
  showTime?: boolean;
  disabled?: boolean;
}

export const DatePicker = (
  {
    name,
    label,
    i18nLabel,
    rules,
    showTime = false,
    disabled,
  }: DatePickerProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <AntdDatePicker disabled={disabled} showTime={showTime} />
    </Form.Item>
  );
};
