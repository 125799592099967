import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import React, { useEffect, useState } from 'react';

import { VoidFnType } from '@/types/common';

interface DrawerProps extends AntdDrawerProps {
  children: React.ReactNode;
  onClose: VoidFnType;
  footer?: (onClose: VoidFnType) => React.ReactNode;
}

export const Drawer = (
  {
    title,
    placement = 'right',
    children,
    closable = true,
    width = 500,
    footer,
    ...props
  }: DrawerProps,
) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const afterVisibleChange = (visibleValue: boolean) => {
    if (!visibleValue) {
      props.onClose();
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <AntdDrawer
      afterVisibleChange={afterVisibleChange}
      title={title}
      placement={placement}
      closable={closable}
      onClose={onClose}
      visible={visible}
      width={width}
      footer={footer && footer(onClose)}
    >
      {children}
    </AntdDrawer>
  );
};
