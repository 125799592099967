import { states as layoutStates } from './components/layout';
import { states as authStates } from './pages/auth';
import { states as usersStates } from './pages/users';
import { states as rolesStates } from './pages/roles';
import { states as commonStates } from './pages/common';
import { states as homeStates } from './pages/home';
import { states as profileStates } from './pages/profile';

export const states = [
  ...authStates,
  ...homeStates,
  ...profileStates,
  ...usersStates,
  ...rolesStates,
  ...commonStates,
  ...layoutStates,
];
