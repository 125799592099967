import { Form, FormItemProps, Transfer as AntdTransfer, TransferProps as AntdTransferProps } from 'antd';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface TransferProps extends FormItemProps, Ii18nLabel, AntdTransferProps<any> {
  children?: any;
}

export const Transfer = (
  {
    name,
    label,
    i18nLabel,
    rules,
    ...props
  }: TransferProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <AntdTransfer {...props} />
    </Form.Item>
  );
};
