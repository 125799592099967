import { buildDropdownOptionsFromState } from '@/utils/common';

export const getFormFields = (permissions) => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'roles.name',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'slugname',
      componentName: 'input',
      params: {
        i18nLabel: 'roles.slugname',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'permissions',
      componentName: 'dropdown-multiple',
      params: {
        i18nLabel: 'roles.permissions',
        options: buildDropdownOptionsFromState(permissions),
      },
      rules: [{ required: true }],
    },
  ];
};
