import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { withStores } from '@/components/stores-provider';

export const Profile = withStores('authStore')(
  observer((
    {
      authStore: {
        profile: {
          id,
          email,
        },
      },
    }: any,
  ) => {
    const userId = id;
    const [currentRoles, setCurrentRoles] = useState<any[]>([]);

    useEffect(() => {
      api.users.get(userId).source
        .then(({ data }) => {
          const roles = data.roles.map(item => item.name);
          setCurrentRoles(roles);
        })
    }, []);
  
    return (
      <>
        <Col>
          <div>
            email: {email}
          </div>
          <div>
            roles: {currentRoles.join(', ')}
          </div>
        </Col>
      </>
    );
  }),
);
