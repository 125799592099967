import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import React, { useMemo } from 'react';

import { getGenericFilters, toQueryFormat } from '@/components/table/utils';
import { CollectionStore } from '@/stores/collection';

interface TableProps extends AntdTableProps<any> {
  store?: CollectionStore<any, any>;
  fillStore?: (params: any) => Promise<any>;
}

export const Table = (props: TableProps) => {
  const { columns, store, fillStore } = props;
  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  const onChangeTable = (...args) => {
    const params = toQueryFormat.apply(null, [genericFilters, ...args]);
    fillStore ? fillStore(params) : store.forceLoad(params);
  };

  const onChange = props.onChange || onChangeTable;
  const pagination = props.pagination !== undefined
    ? props.pagination
    : { total: store.count, showSizeChanger: true };

  return (
    <AntdTable {...props} onChange={onChange} pagination={pagination} />
  );
};
