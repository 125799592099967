import { authStore } from '@/stores';

export const authorizedHook = {
  criteria: {
    to: (state: any) => state.data && state.data.requiresAuth,
  },
  callback: async (transition: any) => {
    const $state = transition?.router?.stateService;

    if (!authStore.isAuthorized()) {
      await authStore.checkSession();

      if (!authStore.loggedIn) {
        return $state.target('auth-login', undefined, { location: true });
      }
    }

    return true;
  },
};
