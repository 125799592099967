import { AccessDenied } from './components/access-denied';

export const states = [
  {
    name: 'base-layout.access-denied',
    url: '/access-denied',
    component: AccessDenied,
    data: {
      requiresAuth: true,
    },
  },
];
