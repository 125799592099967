import React, { createContext, useContext } from 'react';

const StoresContext = createContext({});

export const useStores = (...storeNames: string[]) => {
  const allStores: any = useContext(StoresContext);

  return storeNames.length
    ? storeNames.reduce((selected: any, storeName: string) => {
      if (allStores[storeName]) {
        selected[storeName] = allStores[storeName];
      }
      return selected;
    }, {})
    : allStores;
};

export function withStores(...storeNames: string[]) {
  return (Component: any) => {
    return (props: any) => {
      const newProps = { ...props };
      const context = useStores(...storeNames);
      Object.assign(newProps, context);

      return React.createElement(Component, newProps);
    };
  };
}

export const StoresProvider = ({ children, stores }: any) => {
  return (
    <StoresContext.Provider value={stores}>
      { children }
    </StoresContext.Provider>
  );
};
