import { Form, FormItemProps, InputNumber as AntdInputNumber, InputNumberProps as AntdInputNumberProps } from 'antd';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputProps extends FormItemProps, AntdInputNumberProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
}

export const InputNumber = (
  {
    name,
    label,
    i18nLabel,
    rules,
    min,
    max,
    disabled,
  }: InputProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <AntdInputNumber disabled={disabled} min={min} max={max} />
    </Form.Item>
  );
};
