import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_USER,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { usersStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_USER, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.users.delete(record.id).source
      .then(() => usersStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.users.edit', params: { userId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="users.email" />,
    dataIndex: 'email',
    sorter: true,
  },
  {
    title: <FormattedMessage id="users.roles" />,
    dataIndex: 'roles',
    render: (text: any, row) => {
      return text.map(item => item.name).join(', ');
    },
    sorter: true,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
