import { Form, FormItemProps, Select, SelectProps } from 'antd';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

const { Option } = Select;

interface DropdownProps extends SelectProps<any>, FormItemProps, Ii18nLabel {
  children: any;
}

// tslint:disable-next-line:function-name
export function DropdownMultiple(
  {
    allowClear = true,
    options,
    name,
    label,
    i18nLabel,
    rules,
    showSearch = true,
    optionFilterProp = 'children',
    ...props
  }: DropdownProps,
) {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <Select mode="multiple" {...props} allowClear={allowClear} showSearch={showSearch} optionFilterProp={optionFilterProp}>
        {options.map((item: any) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
      </Select>
    </Form.Item>
  );
}
