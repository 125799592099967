import { authStore } from '@/stores';
import React from 'react';

export function withPermissions(permissions: [string, number][]) {
  return (Component: React.FC<any>) => {
    return (props: Record<string, unknown>) => {
      return authStore.hasPermissions(permissions) && React.createElement(Component, { ...props });
    };
  };
}

export function withAtLeastOnePermission(permissions: [string, number][]) {
  return (Component: React.FC<any>) => {
    return (props: Record<string, unknown>) => {
      return authStore.hasAtLeastOnePermission(permissions) && React.createElement(Component, { ...props });
    };
  };
}
