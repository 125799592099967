import { GenericFormResourceController } from '@/components/generic-form';
import { getValuesDifference } from '@/utils/common';

export const withoutRepeatValue = (
  initValues: any,
  { create, update = (values: any) => Promise.resolve() },
): GenericFormResourceController => {
  return {
    create,
    update: (values: any) => {
      const { changedItem } = getValuesDifference(initValues, values);
      return update(changedItem);
    },
  };
};
