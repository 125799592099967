import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_ROLE,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { rolesStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.roles.delete(record.id).source
      .then(() => rolesStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.roles.edit', params: { roleId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="roles.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="roles.permissions" />,
    dataIndex: 'numberOfPermissions',
    sorter: true,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: Actions,
  },
];
