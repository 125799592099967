import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { usersStore } from '@/stores';
import { rolesStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';

export const UserForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.users.create';
  const { userId } = transition.router.globals.params;
  const user = usersStore.items.find(({ id }: any) => id === +userId);
  const roles = rolesStore.items.map((item) => item);

  const [currentRoles, setCurrentRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(!isCreate);

  useEffect(() => {
    if (!isCreate) {
      api.users.get(userId).source
        .then(({ data }) => {
          const role = data.roles.map(item => item.id);
          setCurrentRoles(role);
          setLoading(false);
        })
    }
  }, []);

  const onClose = () => {
    transition.router.stateService.go('base-layout.users');
  };

  if (loading) {
    return (<>Loading</>);
  }

  const initValues = !isCreate && user
    ? {
      ...user,
      roles: currentRoles,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('users.create-new')
    : getTranslatedString('users.user-number', { 0: userId });

  const formFields = getFormFields(roles, isCreate);

  const getNormalizedValuesInCreate = (values: any) => {
    return {
      ...values,
      roles: undefined,
    };
  };

  const getNormalizedValuesInUpdate = (values: any) => {
    return {
      ...values,
    };
  };

  const resourceController = withoutRepeatValue(initValues, {
    create: async (values: any) => {
      const newUserResponse = await api.users.create(getNormalizedValuesInCreate(values)).source;
      
      const userId = newUserResponse.data.id;

      const newUserWithNewRolesResponse = await api.users.assignRoles(userId, values.roles).source;
      usersStore.addItem(newUserWithNewRolesResponse.data);

      transition.router.stateService.go('base-layout.users.edit', { userId });

      return newUserWithNewRolesResponse;
    },
    update: async (values: any) => {
      await api.users.update(user.id, getNormalizedValuesInUpdate(values)).source;

      const responseWithNewRoles = await api.users.updateRoles(user.id, values.roles).source;
      usersStore.updateItem(responseWithNewRoles.data);

      return responseWithNewRoles;
    }
  });

  return (
    <DrawerForm
      resourceId={userId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !user}
    />
  );
});
