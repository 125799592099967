let internalIntl;

export const preserveIntl = (intl: any) => {
  internalIntl = intl;
};

interface MessageParams {
  [key: string]: any;
}

export const getTranslatedString = (id: string, params?: MessageParams): string => {
  return internalIntl.formatMessage({ id }, params);
};
